import React from "react"
import { Link, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Jumbotron, Container, Row, Col, Card } from "react-bootstrap"
const Prestations = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Nos prestations"
        description="Balade en mer, Pêche en mer, sortie en bateau"
      />
      <Jumbotron fluid className="mb-3">
        <Container>
          <h1>Prestations</h1>
          <p>
            Lors de nos sorties de guidage de pêche, nos moniteurs de pêches
            vous ferons découvrir les techniques, montages, leurres ou appâts en
            fonction du lieu, des conditions et des poissons recherchés. De
            l’initiation au perfectionnement, je m’adapterais en fonction de
            chaque personne.
          </p>
          <p>
            À bord de notre navire de transport de passagés, il sera également
            possible de vous faire découvrir en toute convivialité le milieu
            maritime, la côte d'argent et la côte basque lors de balades, sunset
            ou excursions pour observer les cétacés.
          </p>
          <small>
            Pour l’ensemble des prestations, <b>ne pas oublier</b> : lunettes de
            soleil, crème solaire, casquette, veste ou coupe vent et chaussures
            fermées. <br />
            Le point de rendez-vous avant chaque prestation se situe à la cabane
            Cap Pêche Loisirs
            <u className="mx-1">au port de Capbreton</u> (avenue Georges
            Pompidou, entre le manège et la maison du port).
          </small>
        </Container>
      </Jumbotron>
      <Container fluid="md" className="py-5">
        <Row className="d-flex align-item-center">
          <Col>
            <p>
              <b>Touchez ou cliquez</b> sur un prestation pour voir ses détails
              et informations.
            </p>
          </Col>
        </Row>
        <Row>
          {data.allPrestationCard.nodes.map((prestation, index) => {
            return <PrestationCard key={index} prestation={prestation} />
          })}
        </Row>
      </Container>
    </Layout>
  )
}

function PrestationCard({ prestation, index }) {
  return (
    <>
      <Col md={6} lg={4} className="pb-3 h-100" key={index}>
        <Link
          to={"/" + prestation.slug}
          className="text-decoration-none text-reset"
        >
          <BackgroundImage
            Tag="section"
            fluid={prestation.image.childImageSharp.fluid}
            style={{ height: "250px" }}
            alt={prestation.title}
          >
            <Card.Body
              className="text-light"
              style={{ textShadow: "#474747 1px 2px 2px" }}
            >
              <h4 className="card-title">{prestation.title}</h4>
              <p className="card-text">
                À Partir de <b>{getLowerPrice(prestation)}</b> €.
              </p>
            </Card.Body>
          </BackgroundImage>
        </Link>
      </Col>
    </>
  )
}

export default Prestations

function getLowerPrice(prestation) {
  if (prestation.child_price) {
    return Math.min(prestation.child_price, prestation.adulte_price)
  } else {
    return prestation.adulte_price
  }
}

export const query = graphql`
  query {
    allPrestationCard {
      nodes {
        title
        category
        slug
        image {
          childImageSharp {
            fluid(quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        periode
        duration
        technical
        species
        adulte_price
        description
        meta_description
      }
    }
  }
`
